import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next"
import { API_CORE_BASE_URL, API_BASE_URL } from "../config/apiConfig";
import { useRecoilValue } from "recoil";
import { defaultUseSomeoneDotBotrunAtUrl } from "../recoil/atoms";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    maxWidth: "400px",
  },
  field: {
    margin: "10px 0",
  },
  button: {
    margin: "20px 0",
  },
  feature: {
    margin: "20px 0",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
});

const Welcome: React.FC = () => {
  const { t } = useTranslation()
  const classes = useStyles();
  const defaultUseDotBotrun = useRecoilValue(defaultUseSomeoneDotBotrunAtUrl);

  const handleStartClick = () => {
    const authUrl = (process.env.REACT_APP_USE_DOCKER_IN_DOCKER === 'True' ? API_CORE_BASE_URL : API_BASE_URL) + '/api/botrun/auth-login';

    if (authUrl) {
      window.location.href = authUrl + "?default_botrun=" + defaultUseDotBotrun;
    } else {
      console.error(
        "Welcome.tsx, handleStartClick, Auth URL is not defined in environment variables."
      );
    }
  };

  return (
    <div className={classes.container}>
      <h2>BotRun.ai</h2>
      <div className={classes.feature}>
        <h3>Features:</h3>
        <ul>
          <li>
            <strong>🤖 Domain Bot:</strong> Domain experts can use natural
            language to create and share new bots.
          </li>
          <li>
            <strong>🔠 Dynamic Execution:</strong> With support for 50 different
            programming languages, our AI dynamically generates and executes
            source code.
          </li>
          <li>
            <strong>🔒 Confidential Data:</strong> AI conversation results are
            automatically compiled into a Docker image, data is processed within
            a secure and closed network.
          </li>{" "}
        </ul>
      </div>
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={handleStartClick}
        className={classes.button}
      >
        {t("Start")}
      </Button>
    </div>
  );
};

export default Welcome;
