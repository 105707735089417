import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


const Loading = ({ text = "Loading..." }) => {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
        >
            <Box textAlign="center">
                <CircularProgress sx={{ color: 'white'}} />
                <Typography variant="h6" sx={{ color: 'white'}}>
                    {text}
                </Typography>
            </Box>
        </Box>
    );
};

export default Loading;
