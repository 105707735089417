// ./src/recoil/atoms.ts
import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
import { UserStateProps } from "../types";

const { persistAtom } = recoilPersist();

export const defaultUseSomeoneDotBotrunAtUrl = atom<string>({
  key: "defaultBotrun",
  default: "index",
});

export const userAuthState = atom<UserStateProps | null>({
  key: "userAuthState",
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export const onSendMessageAtom = atom({
  key: 'onSendMessageState',
  default: (message: string, isUserInput: boolean) => {},
});

export const usernameForApiConcateState = atom<string>({
  key: "usernameForApiConcate",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

export const userLogoutState = atom<boolean>({
  key: "userLogoutState",
  default: false,
});