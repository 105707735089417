// src/components/BeginCode.ts
export const beginCode = (
  message: string
): { codePath: string; codeContent: string } => {
  const codePathRegex = /@begin code\("([^"]*)"\)/;
  let codePath = "";
  let codeContent = "";
  const pathMatch = message.match(codePathRegex);
  if (pathMatch) {
    codePath = pathMatch[1];
  }
  const codeContentRegex = /@begin code\("([^"]*)"\)\s*([\s\S]*?)@end/;
  const contentMatch = message.match(codeContentRegex);
  if (contentMatch) {
    codeContent = contentMatch[2].trimStart();
  }

  return { codePath, codeContent };
};
