import { AxiosResponse, AxiosError } from "axios"
import { useQuery } from "react-query"
import { API_BASE_URL } from "../config/apiConfig"
import { getApiUrl } from "../services/apiService"
import { useRecoilValue } from "recoil"
import { usernameForApiConcateState } from "../recoil/atoms"
import axios from "axios"

export default function useSessionId(jwt_token: string | undefined, sessionId: string | undefined) {
  const usernameForApiConcate = useRecoilValue(usernameForApiConcateState)
  const getFormData = () => {
    const data = new FormData()
    data.append("jwt_token", jwt_token as string)
    data.append("session_id", sessionId || "new")
    return data
  }
  const queryFn = () =>
    axios({
      baseURL: API_BASE_URL,
      url: getApiUrl("/api/botrun/new_session_id", usernameForApiConcate),
      method: "post",
      data: getFormData(),
    })

  return useQuery(["fetch-session-id-api", jwt_token], {
    queryFn,
    onError: (error: AxiosError) => {
      console.log(error)
    },
    select: (res: AxiosResponse) => {
      return res.data
    },
    enabled: jwt_token !== undefined,
  })
}
