import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Loading from './Loading'; // 引入 Loading 組件
import { checkUserDocker } from "../services/apiService";
import { useTranslation } from "react-i18next"

const Waiting = () => {
    const { t } = useTranslation()
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const defaultBotrun = params.get("default_botrun") || "index";
    const username = params.get("username") || "";
    const [token, setToken] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [retryCount, setRetryCount] = useState(0); // 新增一個用來追踪重試次數的狀態
    const navigate = useNavigate();

    const checkDockerStatus = async () => {
        if (retryCount >= 10) { // 如果重試次數達到5次，顯示錯誤
            setError(t('Failed to connect to the server after 5 attempts.'));
            return;
        }

        try {
            const returnResult = await checkUserDocker(username?.toString(), defaultBotrun);
            if (returnResult.is_success) {
                setToken(returnResult.access_token);
            } else {
                setRetryCount(retryCount + 1); // 出錯時，重試次數加1
            }
        } catch (err) {
            setError(t('Unable to connect to the server.'));
        }
    };

    useEffect(() => {
        const interval = setInterval(checkDockerStatus, 1000); // 每5秒檢查一次
        return () => clearInterval(interval);
    }, [retryCount]);

    useEffect(() => {
        if (token) {
            navigate(`/?botrun_token=${token}&username=${username}&default_botrun=${defaultBotrun}`);
        }
    }, [token, navigate, username, defaultBotrun]);

    return (
        <div>
            {error ? <p>{t("Error")}: {error}</p> : <Loading text={t("Botrun is starting up, please wait...")} />} {/* 當出現錯誤時顯示錯誤信息，否則顯示加載動畫 */}
        </div>
    );
};

export default Waiting;
